/**
 * BRAND NAMES
 *
*/
export enum brandNames {
  EXPORO = 'Exporo',
  PROPVEST = 'PROPVEST',
}

export default brandNames;
