import axios from 'axios';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import {
    Depot,
    Settings,
    DepotState,
    Documents, ExemptionOrder,
    IncomeStatements,
    Payouts,
    Revenues,
    Saldo,
    SecurityEvents, SecuritySettlements,
    Stocks, TaxCertificates,
    Transactions, TaxEqualizations,
} from './types';
import { RootState } from '@/store/types';

const state: DepotState = {
  user_depot: undefined,
  loadingDepot: false,
  settings: undefined,
  stocks: undefined,
  saldo: undefined,
  exemptionOrder: undefined,
  payouts: undefined,
  transactions: undefined,
  revenues: undefined,
  loadingDocuments: false,
  documents: undefined,
  blocked: false,
};

const mutations: MutationTree<DepotState> = {
  SET_LOADING_DEPOT(state: DepotState, loadingDepot: Boolean) {
    state.loadingDepot = loadingDepot;
  },
  SET_DEPOT(state: DepotState, depot: Depot) {
    state.user_depot = depot;
  },
  SET_SETTINGS(state: DepotState, settings: Settings) {
      state.settings = settings;
  },
  SET_STOCKS(state: DepotState, stocks: Stocks) {
    state.stocks = stocks;
  },
  SET_TRANSACTIONS(state: DepotState, transactions: Transactions) {
    state.transactions = transactions;
  },
  SET_REVENUES(state: DepotState, revenues: Revenues) {
     state.revenues = revenues;
  },
  SET_SALDO(state: DepotState, saldo: Saldo) {
     state.saldo = saldo;
  },
  SET_EXEMPTION_ORDER(state: DepotState, exemptionOrder: ExemptionOrder) {
      state.exemptionOrder = exemptionOrder;
  },
  SET_PAYOUTS(state: DepotState, payouts: Payouts) {
      state.payouts = payouts;
  },
  SET_LOADING_DOCUMENTS(state: DepotState, loadingDocuments: Boolean) {
      state.loadingDocuments = loadingDocuments;
  },
  SET_DOCUMENTS(state: DepotState, documents: Documents) {
      state.documents = documents;
  },
  SET_BLOCKED(state: DepotState, blocked: boolean) {
      state.blocked = blocked;
  },
};

const getters: GetterTree<DepotState, RootState> = {
  loadingDepot: (state: DepotState) => state.loadingDepot,
  depot: (state: DepotState) => state.user_depot,
  settings: (state: DepotState) => state.settings,
  stocks: (state: DepotState) => state.stocks,
  payouts: (state: DepotState) => state.payouts,
  saldo: (state: DepotState) => state.saldo,
  exemptionOrder: (state: DepotState) => state.exemptionOrder,
  transactions: (state: DepotState) => state.transactions,
  revenues: (state: DepotState) => state.revenues,
  loadingDocuments: (state: DepotState) => state.loadingDocuments,
  documents: (state: DepotState) => state.documents,
  blocked: (state: DepotState) => state.blocked,
  getStockById: (state: DepotState) => (stockId: string) => {
    return !!stockId && state.stocks
    ? state.stocks?.data.find(stock => stock.wpNr === stockId)
    : null;
  },
};

const actions: ActionTree<DepotState, RootState> = {

  fetchUserDepotData({ commit, rootState, dispatch }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }

    commit('SET_LOADING_DEPOT', true);

    return axios.get(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/cockpit?user=` + rootState.profile.baaderUser.user_id)
        .then(response => {

          const transactions: Transactions = {
            data: response.data.transactions,
          };
          const stocks: Stocks = {
            data: response.data.stocks,
          };
          const revenues: Revenues = {
            data: response.data.revenues,
          };
          const payouts: Payouts = {
              data: response.data.payouts,
          };

          commit('SET_DEPOT', response.data.depot);
          commit('SET_SETTINGS', response.data.settings);
          commit('SET_STOCKS', stocks);
          commit('SET_TRANSACTIONS', transactions);
          commit('SET_REVENUES', revenues);
          commit('SET_PAYOUTS', payouts);
          commit('SET_SALDO', response.data.saldo);
          commit('SET_EXEMPTION_ORDER', response.data.exemptionOrder);
          commit('SET_BLOCKED', response.data.blocked);

          return Promise.resolve(response.data);
        })
        .catch(error => {
          commit('SET_DEPOT', undefined);

          return Promise.reject(error.response);
        })
        .finally(() => commit('SET_LOADING_DEPOT', false));
  },

  fetchDocuments({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
        return null;
    }
    commit('SET_LOADING_DOCUMENTS', true);
    commit('SET_DOCUMENTS', undefined);

    return axios.get(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/document?user=` + rootState.profile.baaderUser.user_id)
        .then(response => {

          const securityEventsOld: SecurityEvents = {
              data: response.data['wertpapier-ereignisse'],
          };

            const securityEvents: SecurityEvents = {
                data: response.data['wertpapier-ereignis-swift-faehig'],
            };

          const incomingStates: IncomeStatements = {
              data: response.data['ertraegnisaufstellung-neu'],
          };

          const taxCertificates: TaxCertificates = {
              data: response.data['jstb-1'],
          };

            const taxEqualization: TaxEqualizations = {
                data: response.data['topfausgleich'],
            };

          const securitySettlements: SecuritySettlements = {
              data: response.data.wertpapierabrechnung,
          };

          const documents: Documents = {
              securityEventsOld: securityEventsOld,
              securityEvents: securityEvents,
              taxEqualization: taxEqualization,
              incomeStatements: incomingStates,
              taxCertificates: taxCertificates,
              securitySettlements: securitySettlements,
          };

          commit('SET_DOCUMENTS', documents);

          return Promise.resolve(response.data);
        })
        .catch(error => {
          commit('SET_DOCUMENTS', undefined);

          return Promise.reject(error.response);
        })
        .finally(() => commit('SET_LOADING_DOCUMENTS', false));
  },

  createPayOut({ dispatch }: { dispatch: Function }, { amount }: { amount: string}) {
    const payload = {
        amount: amount,
    };

    return axios.post(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/payout`, payload)
      .finally( () => {
        dispatch('fetchUserDepotData', {});
      });
  },

  updateSetting({ dispatch }: { dispatch: Function }, { setting, value }: { setting: string, value: boolean}) {
    const payload = {
      setting: setting,
      value: value,
    };

    return axios.post(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/update-account-setting`, payload)
      .finally( () => {
        dispatch('fetchUserDepotData', {});
      });
    },
};

export const depot: Module<DepotState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
