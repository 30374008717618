import axios from 'axios';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { TradingState, Asset, SellOffer, MySellOffer } from './types';
import { RootState } from '@/store/types';

const state: TradingState = {
  projects: undefined,
  myProjects: undefined,
  selloffers: undefined,
  mySellOffers: undefined,
  loading: false,
};

const mutations: MutationTree<TradingState> = {
  SET_LOADING(state: TradingState, loading: boolean) {
    state.loading = loading;
  },
  SET_PROJECTS(state: TradingState, projects: Asset[]) {
    state.projects = projects;
  },
  SET_MYPROJECTS(state: TradingState, projects: Asset[]) {
    state.myProjects = projects;
  },
  SET_SELLOFFERS(state: TradingState, selloffers: SellOffer[]) {
    state.selloffers = selloffers;
  },
  SET_MYSELLOFFERS(state: TradingState, mySellOffers: MySellOffer[]) {
    state.mySellOffers = mySellOffers;
  },
};

const getters: GetterTree<TradingState, RootState> = {
  projects: (state: TradingState) => state.projects,
  selloffers: (state: TradingState) => state.selloffers,
  mySellOffers: (state: TradingState) => state.mySellOffers,
  loading: (state: TradingState) => state.loading,
  getSellOffersByAssetId: (state: TradingState) => (assetId: string) => {
    return !!assetId && !!state.selloffers
      ? state.selloffers?.filter(sellOffer => sellOffer.asset_id === assetId)
        .sort((a, b) => a.unit_price <= b.unit_price ? -1 : 1)
      : [];
  },
  getSellOfferById: (state: TradingState) => (sellOfferId: number) => {    
    return !!sellOfferId && state.selloffers
    ? state.selloffers?.find(sellOffer => sellOffer.id === sellOfferId)
    : null;
  },
  getProjectByAssetId: (state: TradingState) => (assetId: string) => {
    return !!assetId && state.projects
    ? state.projects?.find(project => project.asset_id === assetId)
    : null;
  },
  getMyProjectByAssetId: (state: TradingState) => (assetId: string) => {
    return !!assetId && state.myProjects
    ? state.myProjects?.find(project => project.asset_id === assetId)
    : null;
  },
  
  getAssetLowestOffer: (state: TradingState, getters: any) => (assetId: string) => {
    return getters.getSellOffersByAssetId(assetId)
      .sort((a, b) => a.unit_price <= b.unit_price ? -1 : 1)
      .reverse()
      .pop();
  },
};

const actions: ActionTree<TradingState, RootState> = {
  // fetch https://handelsplatz.exporo.de/ project data
  fetchAssets({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.get(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/securities/bonds/`)
      .then(({ data }): { data: Asset[] } => {
        // filter baader bank assets
        const projects = data.filter(bond => bond.provider === 'baaderbank');
        commit('SET_PROJECTS', projects);

        return data;
      })
      .catch((error) => {
        console.error(error);
        commit('SET_PROJECTS', undefined);

        return error;
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  fetchMyAsset({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }, assetId: string) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.get(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/my/securities/bonds/${assetId}/`)
      .then(({ data }): { data: Asset[] } => {
        const projects = [ data ];
        commit('SET_MYPROJECTS', projects);

        return data;
      })
      .catch((error) => {
        console.error(error);
        commit('SET_MYPROJECTS', undefined);

        return error;
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  fetchSellOffers({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.get(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/orderbook/selloffer?brand=exporo`)
      .then(({ data }) => {
        commit('SET_SELLOFFERS', [...data]);
      })
      .catch((error) => {
        console.error(error);
        commit('SET_SELLOFFERS', undefined);

        return Promise.reject(error);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  fetchMySellOffers({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.get(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/my/selloffers/active`)
      .then(({ data }) => {
        commit('SET_MYSELLOFFERS', [...data]);
      })
      .catch((error) => {
        console.error(error);
        commit('SET_MYSELLOFFERS', undefined);

        return Promise.reject(error);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  async buySellOffer({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }, { id, buySellOffer }) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.post(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/orderbook/selloffer/${id}/trades`, buySellOffer)
      .then(({ data }) => {
        return data;
      })
      .catch ((error)  => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  async createSellOffer({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }, sellOffer ) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.post(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/orderbook/selloffer`, sellOffer)
      .then(({ data }) => {
        return data;
      })
      .catch ((error)  => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },

  async cancelSellOffer({ commit, rootState }: { commit: Function, rootState: any, dispatch: Function }, id ) {
    if (!rootState.profile.baaderUser) {
      return null;
    }
    commit('SET_LOADING', true);

    return axios.delete(`${process.env.VUE_APP_EXPORO_TRADING_API_URL}/orderbook/selloffer/${id}`)
      .catch ((error)  => {
        return Promise.reject(error);
      })
      .finally(() => {
        commit('SET_LOADING', false);
      });
  },
};

export const trading: Module<TradingState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
