import axios from 'axios';
import * as Sentry from '@sentry/browser';
import { signOut, getIdToken } from '@/utils/Auth';
import { redirectToLogin } from '@/utils/Router';

import store from '@/store';

const unauthorizedStatus = [ 401, 403 ];
const intendedStatus = [ 422, 406, 417 ];

export function configAxios() {
  axios.interceptors.request.use(
    (config) => {
      store.dispatch('appState/startLoader');

      // Automatically authorize request headers with session data
      const token = getIdToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return Promise.resolve(config);
    },
    (error) => {
      store.dispatch('appState/stopLoader');

      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response) => {
      store.dispatch('appState/stopLoader');

      // Any status code that lie within the range of 2xx cause this function to trigger

      // Check for response for Forbidden and or Unauthorized codes to send user to login page
      if (unauthorizedStatus.includes(response.status)) {
        signOut();
      }

      return Promise.resolve(response);
    }, (error) => {
      store.dispatch('appState/stopLoader');

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Check for response for Forbidden and or Unauthorized codes to send user to login page
      if (error && error.response && unauthorizedStatus.includes(error.response.status)) {
        signOut();

        return redirectToLogin();
      }

      if (error && error.response && intendedStatus.includes(error.response.status)) {
          return Promise.reject(error);
      }

      Sentry.setExtra('error', error);
      Sentry.captureException(error);

      return Promise.reject(error);
    });

  return axios;
}
