import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import LocalStorage from '@/utils/LocalStorage';
import { validateSession } from '@/utils/Auth';
import { redirectToLogin } from '@/utils/Router';
import DepotView from '@/views/depot/DepotView.vue';

Vue.use(VueRouter);

const isDevEnvironment = window.location.host.indexOf('dev.') > -1;

const routes = [
  {
    // main init view
    path: '/depot',
    name: 'Exporo Baader Depot',
    meta: { requiresAuth: true },
    component: DepotView,
    redirect: '/depot/dashboard',
    children: [
      {
        // DocumentsView will be rendered inside DepotView's <router-view>
        // when '/depot/documents' is matched
        path: 'documents',
        name: 'documents',
        component: () => import('@/views/depot/DocumentsView.vue'),
      },
      {
        // ExemptionOrderView will be rendered inside DepotView's <router-view>
        // when '/depot/exemption-order' is matched
        path: 'exemption-order',
        name: 'exemption-order',
        component: () => import('@/views/depot/ExemptionOrderView.vue'),
      },
      {
        // WithdrawDepotView will be rendered inside DepotView's <router-view>
        // when '/depot/withdraw' is matched
        path: 'withdraw',
        name: 'Withdraw',
        component: () => import('@/views/depot/WithdrawDepotView.vue'),
      },
      {
        // DashboardView will be rendered inside DepotView's <router-view>
        // when '/depot/dashboard' is matched
        path: 'dashboard',
        name: 'Dashboard',
        component: () => import('@/views/depot/DashboardView.vue'),
      },
      {
        // TradingView will be rendered inside DepotView's <router-view>
        // when '/depot/trading' is matched
        path: 'trading',
        component: () => import('@/views/depot/TradingView.vue'),
        children: [
          {
            // TradingListView will be rendered inside TradingView's <router-view>
            // when '/depot/trading' is matched
            path: '',
            name: 'tradingListView',
            meta: { requiresAuth: true },
            component: () => import('@/views/depot/TradingListView.vue'),
          },
          {
            // CreateSellOfferView will be rendered inside TradingView's <router-view>
            // when '/depot/trading/create-sell-offer' is matched
            path: 'create-sell-offer/:assetId',
            name: 'createSellOffer',
            meta: { requiresAuth: true },
            component: () => import('@/views/depot/CreateSellOfferView.vue'),
          },
          {
            // BuySellOfferView will be rendered inside TradingView's <router-view>
            // when '/depot/trading/buy-sell-offer/:sellOfferId' is matched
            path: 'buy-sell-offer/:sellOfferId',
            name: 'buySellOffer',
            meta: { requiresAuth: true },
            component: () => import('@/views/depot/BuySellOfferView.vue'),
          },
        ],
      },
    ],
  },
  {
    // redirect unknown routes to depot
    path: '*',
    redirect: '/depot',
  },
];

const router = new VueRouter({
  mode: isDevEnvironment ? 'hash' : 'history',
  base: '/',
  routes,
});

router.beforeEach(async (to, from, next) => {
  // handle route redirects
  if (LocalStorage.has('REDIRECT_TO')) {
    const redirect = LocalStorage.get('REDIRECT_TO') as string;
    LocalStorage.remove('REDIRECT_TO');

    return next(redirect);
  }

  const validSession = await validateSession();
  // routes that require authentication
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (!validSession) {
      // user not signed in. store route to use after cockpit signin redirection
      const { fullPath } = to;
      LocalStorage.add('REDIRECT_TO', fullPath);

      return redirectToLogin();
    }

    // check that user is already loaded, if not, fetch user data
    if (!store.getters['profile/baaderUser']) {
      await store.dispatch('profile/fetchIdToken');

      try {
        await store.dispatch('profile/validateUser', to.query.user);
      } catch (error) {
        // user does not have a depot. show contact support message
        if (error.status === 417) {
          store.dispatch('appState/createDepotAccount');
        }
      }
    }

    // check that trading platform projects are already loaded, if not, fetch TP assets
    if (!store.getters['trading/projects']) {
      await store.dispatch('trading/fetchAssets', {}, { root: true });
    }
  }

  return next();
});

export default router;
