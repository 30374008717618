
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'app-loading-bar',
})
export default class AppLoadingBar extends Vue {
  @Prop({ default: false })
  isLoading: boolean
}
