import SessionStorage from '@/utils/SessionStorage';
import { gtmActions, gtmTrack } from '@/utils/gtm';
import parseJWT from '@/utils/parseJWT';

/**
 * Forces first session check to fetch tokens from ${getOnboardingDomainURL(VUE_APP_EXPORO_USER_URL)}
 * Afterwards, tokens are retreived from SessionStorage
 *
 * @type {boolean}
 */
let isSessionFirstCheck = true;

/**
 * Retrieves the AccessToken from session storage
 */
export const getAccessToken = () => SessionStorage.get('USER_ACCESS_TOKEN');

/**
 * Retrieves the IdToken from session storage
 */
export const getIdToken = () => SessionStorage.get('USER_ID_TOKEN');

/**
 * Retrieves the RefreshToken from session storage
 */
export const getRefreshToken = () => SessionStorage.get('USER_REFRESH_TOKEN');

/**
 * Clears session storage data
 */
export const signOut = () => {
  SessionStorage.remove('USER_ID_TOKEN');
  SessionStorage.remove('USER_ACCESS_TOKEN');
  SessionStorage.remove('USER_REFRESH_TOKEN');
};

/**
 * Tries to authenticate the user and save all token data in session storage
 * 1 - get a refreshToken
 * 2 - exchange refreshToken for idToken and accessToken
 *
 * @example
 * const validSession = await validateSession();
 *
 * @returns {boolean} user - authentication state
 */
export const validateSession = async () => {
  const refreshToken = await fetchRefreshToken();
  let session;

  if (refreshToken) {
    session = await getSessionTokens(refreshToken as string);
  }

  if (session) {
    isSessionFirstCheck = false;

    const { IdToken, AccessToken } = session;

    return !!refreshToken && !!IdToken && !!AccessToken;
  }

  signOut();

  return false;
};

/**
 * Get a refresh token
 * If there is no refresh token set in localstorage,
 * Opens an iframe containing the user login application to read the session cookie
 *
 * @example
 * const refreshToken = await fetchRefreshToken();
 *
 * @returns {string} refreshToken - the cognito refresh token
 */
export const fetchRefreshToken = () => {
  if(new URL(window.location.href).searchParams.get('refreshToken')) {
    SessionStorage.add('USER_REFRESH_TOKEN', new URL(window.location.href).searchParams.get('refreshToken'));
  }
  const refreshToken = getRefreshToken();
  if (!isSessionFirstCheck && refreshToken) {
    return refreshToken;
  }

  const sessionIframe = document.createElement('iframe');
  sessionIframe.id = 'ExporoUserSession';
  sessionIframe.style.display = 'none';
  sessionIframe.src = `${getOnboardingDomainURL(process.env.VUE_APP_EXPORO_USER_API)}/user/session`;

  return new Promise((resolve, reject) => {
    try {

      function handleMessage(event) {
        if (typeof event.data === 'string' && event.data.includes('sso')) {
          const postedMessage = JSON.parse(event.data);
          // console.warn('sso:session:message:', postedMessage);
          window.removeEventListener('message', handleMessage);
          document.body.removeChild(sessionIframe);

          if (postedMessage.payload.error) {
            // no session
            resolve(null);
          } else if (postedMessage.payload.refreshToken) {
            // has session
            SessionStorage.add('USER_REFRESH_TOKEN', postedMessage.payload.refreshToken);

            resolve(postedMessage.payload.refreshToken);
          }
        }
      };

      window.addEventListener('message', handleMessage);
      document.body.appendChild(sessionIframe);
    } catch (error) {
      resolve(null);
    }
  });
};

/**
 * Exchanges a refresh token for an idToken and an accessToken
 * Tokens are stored in SessionStorage for later reuse
 *
 * @example
 * const session = await getSessionTokens(<refreshToken>);
 *
 * @param {string} refreshToken
 *
 * @returns {Object} session - user tokens
 * @returns {string} session.IdToken - the cognito idToken
 * @returns {string} session.AccessToken - the cognito accessToken
 * @returns {string} session.RefreshToken - the cognito refreshToken
 */
export const getSessionTokens = async (refreshToken: string) => {
  const IdToken = getIdToken();
  const AccessToken = getAccessToken();

  if (!isSessionFirstCheck && !!AccessToken && IdToken) {
    return {
      IdToken,
      AccessToken,
      RefreshToken: refreshToken,
    };
  }

  const USER_POOL_ID = process.env.VUE_APP_USER_POOL_ID || '';
  const AWS_REGION = USER_POOL_ID.split('_')[0];
  const USER_POOL_CLIENT_ID = process.env.VUE_APP_USER_POOL_CLIENT_ID;

  const endpoint = `https://cognito-idp.${AWS_REGION}.amazonaws.com/`;

  return await fetch(endpoint, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
    cache: 'no-cache',
    body: JSON.stringify({
      ClientId: USER_POOL_CLIENT_ID,
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      AuthParameters: {
        REFRESH_TOKEN: refreshToken,
      },
    }),
  })
    .then((res) => res.json())
    .then(({ AuthenticationResult }) => {
      const { IdToken, AccessToken } = AuthenticationResult;

      const decodedJWT = parseJWT(IdToken);
      // Add tracking action to Google tag Manager dataLayer
      const trackData = { userId: decodedJWT.user_id };
      gtmTrack(gtmActions.USER_AUTH_SUCCESSFUL, trackData);

      SessionStorage.add('USER_ID_TOKEN', IdToken);
      SessionStorage.add('USER_ACCESS_TOKEN', AccessToken);

      return {
        IdToken,
        AccessToken,
        refreshToken,
      };
    })
    .catch(res => {
      return Promise.reject(res);
    });
};

export const getOnboardingDomainURL = (url) => {
  const { host } = window.location;

  if (host.includes('propvest')) {
    return url.replace('exporo.com', 'propvest.de');
  }

  if (host.includes('exporo.de')) {
    return url.replace('exporo.com', 'exporo.de');
  }

  return url;
};
