import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { StepperState, Steps } from './types';
import { RootState } from '../types';

const state: StepperState = {
  active: 0,
  steps: [],
};

const mutations: MutationTree<StepperState> = {
  SET_STEPS(state: StepperState, steps: Steps) {
    state.steps = steps;
  },

  SET_ACTIVE(state: StepperState, active: number) {
    state.active = active;
  },

  SET_VALID_CURRENT_STEP(state: StepperState, valid: boolean) {
    if(!!state.steps[state.active]){
      state.steps[state.active].valid = valid;
    }
  },
  
  NEXT(state: StepperState) {
    state.active = state.steps.length >= state.active++ ? state.active : ++state.active;
  },

  BACK(state: StepperState) {
    state.active = state.steps.length === 0 ? 0 : --state.active;
  },

};

const getters: GetterTree<StepperState, RootState> = {
  steps: (state: StepperState) => state.steps,
  activeStep: (state: StepperState) => state.active,
};

const actions: ActionTree<StepperState, RootState> = {

  setSteps({ commit }: { commit: Function}, steps: Steps) {
    commit('SET_STEPS', steps);
  },

  setActive({ commit }: { commit: Function}, active: number) {
    commit('SET_ACTIVE', active);
  },

  setValidCurrentStep({ commit }: { commit: Function}, valid: boolean) {
    commit('SET_VALID_CURRENT_STEP', valid);
  },

  nextStep({ commit }: { commit: Function }) {
    commit('NEXT');
  },

  backStep({ commit }: { commit: Function }) {
    commit('BACK');
  },
};

export const stepper: Module<StepperState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
