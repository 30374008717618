
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';

import AppLoadingBar from '@/components/app/AppLoadingBar.vue';
import AppSidebar from '@/components/app/AppSidebar.vue';
import AppHeader from '@/components/app/AppHeader.vue';
import ExporoLoading from '@/components/app/ExporoLoading.vue';
import Modal from '@/components/_common/Modal.vue';
import { currency } from '@/filters/currency';
import { Payouts, Saldo } from '@/store/depot/types';
import exporoPagesEnum from '@/enum/exporo/exporoPagesEnum';
import { Brand, getBrandSchema } from '@/utils/brand-schemas';
import { brandNames } from '@/enum/brands';
import { ExporoUser } from '@/store/profile/types';
import SessionStorage from '@/utils/SessionStorage';

@Component({
  components: {
    AppHeader,
    AppLoadingBar,
    ExporoLoading,
    AppSidebar,
    Modal,
  },
  filters: {
    currency,
  },
})
export default class App extends Vue {
  @Getter('appState/menuOpen') menuOpen?: boolean;
  @Getter('appState/isLoading') isLoading?: boolean;
  @Getter('appState/createDepotAccount') createDepotAccount?: boolean;
  @Getter('appState/brandSchema') brandSchema?: Brand;

  @Getter('profile/fullName') fullName?: string;
  @Getter('profile/exporoId') exporoId?: string;
  @Getter('profile/exporoUser') exporoUser: ExporoUser;

  @Getter('depot/saldo') saldo?: Saldo;
  @Getter('depot/payouts') payouts?: Payouts;
  @Getter('depot/loadingDepot') loadingDepot?: boolean;

  @Action('appState/setBrandSchema') setBrandSchema: Function;
  @Action('appState/toggleMenu') toggleMenu: Function;

  @Action('profile/signOut') signOut: Function;

  private scriptsToLoad: number = 0;
  private loadedScripts: number = 0;
  private isWebview: boolean = false;

  get brandName() {
    return this.brandSchema.name.toLowerCase();
  }

  get isLoadingUser(): Boolean {
    return !this.exporoId;
  }

  get balance() {
    return this.$options.filters.currency(this.saldo?.saldo || 0);
  }

  get payoutBalance() {
    return this.$options.filters.currency(
      this.payouts?.data.reduce(
        (sum, payout) => sum + parseFloat(payout.amount),
        0
      )
    );
  }

  get dashboardURL() {
    if(this.isPropvest){
      return process.env.VUE_APP_PROPVEST_URL;
    }

    return exporoPagesEnum.COCKPIT.url;
  }

  get isPropvest() {
    return this.brandName === brandNames.PROPVEST.toLowerCase();
  }

  get isExporoWeb() {
    return !this.isPropvest && !this.isWebview;
  }

  get logoURL() {
    return `${process.env.VUE_APP_PROPVEST_URL}/home`;
  }

  beforeMount() {
    // Configure application branding
    const schema: Brand = getBrandSchema();
    this.setBrandSchema(schema);
    
    if(window.location.hash.includes('isWebview') || window.location.href.includes('isWebview')) {
      this.isWebview = true;
    }

    const favicon = document.getElementById('favicon') as HTMLAnchorElement;
    favicon.href = schema.favicon;
    if (this.isPropvest) {
      this.addScript('https://cdn.brand.propvest.com/header.umd.js');
      this.addScript('https://cdn.brand.propvest.com/footer.umd.js');
    }
  }

  async mounted() {
    // configure css colors based on brandschema
    Object.keys(this.brandSchema.colors).map((colorKey) => {
      const color = this.brandSchema.colors[colorKey];
      document
        .getElementsByTagName('body')[0]
        .style.setProperty(`--${colorKey}`, color);
    });
    document
      .getElementsByTagName('body')[0]
      .style.setProperty('background', this.brandSchema.colors.appBackground);
    await this.$nextTick();

    this.$forceUpdate();
  }

  get user() {
    return this.exporoUser?.id
      ? encodeURI(
          JSON.stringify({
            userId: Number(this.exporoUser.id),
            firstName: this.exporoUser.naturalUser.firstName,
            lastName: this.exporoUser.naturalUser.lastName,
          })
        )
      : null;
  }

  get propvestHeaderRoutes() {
    return encodeURI(
      JSON.stringify([
        {
          name: 'Dashboard',
          url: `${process.env.VUE_APP_PROPVEST_DASHBOARD_URL}/`,
          title: 'Dashboard',
          icon: 'Dashboard',
          active: false,
        },
        {
          name: 'Portfolio',
          url: `${process.env.VUE_APP_PROPVEST_URL}/portfolio`,
          title: 'Portfolio',
          icon: 'Portfolio',
          active: false,
        },
        {
          name: null,
          url: process.env.VUE_APP_PROPVEST_MARKETPLACE,
          title: 'Invest',
          icon: 'Invest',
          active: false,
        },
      ])
    );
  }

  addScript(src: string) {
    // adds a script to html head
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = src;
    script.onload = this.scriptLoaded;
    this.scriptsToLoad++;
    document.head.appendChild(script);
  }

  scriptLoaded(){
    this.loadedScripts ++;
  }

  get allScriptsLoaded() {
    return this.loadedScripts === this.scriptsToLoad;
  }
}
