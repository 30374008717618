
import { Brand } from '@/utils/brand-schemas';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  name: 'modal',
})
export default class Modal extends Vue {
  @Prop({ required: true }) private active!: Boolean;
  @Prop({ required: true }) private name!: String;
  @Prop({ default: null }) private onClose: Function;
  @Getter('appState/brandSchema') private brandSchema?: Brand;

  @Watch('active')
  onPropertyChanged(value: boolean, oldValue: boolean, deep: true) {
    if (!value) {
      document.getElementById('app').className = '';
    } else {
      document.getElementById('app').className = 'no-overflow';
    }
  }
  get logoUrl() {
    return this.brandSchema.mbLogoLink;
  }

  get imgURL() {
    return require(`@/assets/${this.logoUrl}`);
  }
}
