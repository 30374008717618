import Vue from 'vue';
import I18nPlugin from '@exporo/educts-component-i18n';
import LocalStorage from '@/utils/LocalStorage';

declare module 'vue/types/vue' {
  interface Vue {
    $i18n: any,
    $t: any
  }
}
export function configI18n() {
  Vue.use(I18nPlugin);

  if (LocalStorage.has('i18nextLng')) {
    // educts pluggin overrides localstorage with 'fallbackLng' so we need to set it back
    const locale = LocalStorage.get('i18nextLng');
    const { $i18n } = Vue.prototype;

    $i18n.on('initialized', (options) => {
      $i18n.locale = locale;
      $i18n.fallbackLng = locale;
      $i18n.changeLanguage(locale);
    });
  }
}
