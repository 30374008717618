import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import {BaaderUser} from '@/store/profile/types';

const isLocal = window.location.origin.includes('http://localhost');
const environment = process.env.VUE_APP_NODE_ENV || null;

export function configSentry() {
  // configure Sentry
  // allow error tracking in staging / prod
  if (!isLocal && environment) {
    Sentry.init({
      dsn: 'https://41f6fe6a006d491cb78822819a5517a9@o38895.ingest.sentry.io/5416761',
      integrations: [new VueIntegration({Vue, attachProps: true, logErrors: true})],
      environment,
    });
  }
}


export function identifySentryUser(user:BaaderUser){
  if (!isLocal && environment) {
    // Capture user
    // https://docs.sentry.io/enriching-error-data/additional-data/?platform=javascript#capturing-the-user
    Sentry.setUser({
      id: String(user.user_id),
    });

    // Set user aditional information, as well as tags and further extras
    // https://docs.sentry.io/enriching-error-data/additional-data/?platform=javascript#custom-data
    Sentry.configureScope((scope) => {
      scope.setExtra('ext_user_id', String(user.user_id));
      scope.setExtra('baader_snr', String(user.snr));
    });
  }
}