
import { Component, Prop, Vue } from 'vue-property-decorator';


@Component({
  name: 'exporo-loading',
})
export default class ExporoLoading extends Vue {
  @Prop({ default: false, required: true }) private isLoading: Boolean;

  created() {
    document.getElementsByTagName('body')[ 0 ].className = 'no-overflow';
  }

  destroyed() {
    document.getElementsByTagName('body')[ 0 ].className = '';
  }
}
