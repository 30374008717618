import LocalStorage from '@/utils/LocalStorage';


function getLocale(){
  return LocalStorage.get('i18nextLng') || process.env.VUE_APP_EXPORO_LANGUAGE;
}

export function centsToEuro(value: string): string {
  let amount = `${parseInt(value) / 100}`;

  amount = parseFloat(amount).toFixed(2);

  return currency(amount);
}

export function currency(amount: string, locale: string = getLocale(), currency: string = 'EUR') {
  return new Intl.NumberFormat(locale, { style: 'currency', currency })
    .format(parseFloat(amount));
}
