declare global {
    interface Window {
        dataLayer: Array<any>;
    }
}

export enum gtmActions {
    USER_AUTH_SUCCESSFUL = 'USER_AUTH_SUCCESSFUL',
}

export function gtmTrack (trackAction: gtmActions, data: any = null) {
    switch (trackAction) {
        case gtmActions.USER_AUTH_SUCCESSFUL:
            if(!data || !data.userId) return false;

            window.dataLayer.push({
                'userID': data.userId || null,
                'event': 'gtmEvent',
                'eventCategory': 'user',
                'eventAction': 'trackUser',
                'eventLabel': data.userId || null,
                'eventProperty': null,
            });

            break;
        default:
            return false;
    }

    return  true;
}
