import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { appState } from '@/store/appState';
import { profile } from '@/store/profile';
import { depot } from '@/store/depot';
import { pat } from '@/store/pat';
import { trading } from '@/store/trading';
import { stepper } from '@/store/stepper';


Vue.use(Vuex);

/**
 * Vuex and Typescript pattern
 * @see https://codeburst.io/vuex-and-typescript-3427ba78cfa8
 */
const store: StoreOptions<RootState> = {
  state: {
    version: '1.0.0', // a simple property
  },
  modules: {
    appState,
    profile,
    depot,
    pat,
    trading,
    stepper,
  },
};

export default new Vuex.Store<RootState>(store);
