import { brandNames } from '@/enum/brands';

export type Brand = {
  default?: boolean,
  name: string,
  url: string,
  dtLogoLink: string,
  mbLogoLink: string,
  headerLogo: string,
  logoContainsName: boolean,
  backgroundImage?: string,
  footerLogo?: string,
  favicon: string,
  supportEmail: string,
  supportPhone: string,
  colors: {
    [key: string]: string,
    primary: string,
    secondary: string,
    accent: string,
    error: string,
    info: string,
    success: string,
    warning: string,
  },
}

export const exporo: Brand = {
  name: brandNames.EXPORO,
  favicon: './favicon.ico',
  url: process.env.VUE_APP_EXPORO_FINANCING_URL!,
  dtLogoLink: 'logo/squared/exporo_logo.svg',
  mbLogoLink: 'logo/horizontal/exporo-logo-light.svg',
  headerLogo: 'logo/horizontal/exporo-logo-dark.svg',
  footerLogo: 'logo/icons/exporo_icon.svg',
  logoContainsName: true,
  supportEmail: 'service@exporo.com',
  supportPhone: '+49 (0) 40 / 228 686 99 - 0',
  colors: {
    appBackground: '#fafafa',
    // https://www.figma.com/file/yTAx7nYzrWSM8yBcwlkkvP/EXP-Styleguide?node-id=0%3A1&viewport=-5504%2C258%2C0.4953627586364746
    info: '#2196F3',
    warning: '#FFC107',

    primary: '#153251',
    'primary-1': '#FCFCFD',
    'primary-2': '#1F4B7A',
    'primary-3': '#1f2845',
    'primary-4': '#1f4b7a',

    accent: '#FFAB00',
    secondary: '#1f4b7a',
    'secondary-1': '#FFF7E6',
    'secondary-2': '#FDEDCC',
    'secondary-3': '#FDE5B3',
    'secondary-4': '#FFAB00',
    'secondary-5': '#FFAB00',
    'secondary-6': '#CC7700',

    'grey-900': '#303841',
    'grey-800': '#303841',
    'grey-700': '#575F6A',
    'grey-600': '#727B87',
    'grey-500': '#8F99A6',
    'grey-400': '#ACB7C6',
    'grey-300': '#BDC5D2',
    'grey-200': '#eeeeee',
    'grey-100': '#f5f5f5',
    'grey-50': '#fafafa',

    'text-light': '#ECEFF4',
    'text-dark': '#153251',

    success: '#10b610',
    error: '#E03D3D',

    // product colors
    'product-1': '#3968AF',
    'product-2': '#86AC48',
    'product-3': '#153251',
  },
};

export const propvest: Brand = {
  name: brandNames.PROPVEST,
  favicon: './favicon_propvest.png',
  url: process.env.VUE_APP_PROPVEST_URL!,
  dtLogoLink: 'logo/squared/propvest-logo_200x150.svg',
  mbLogoLink: 'logo/horizontal/propvest-logo-light.svg',
  headerLogo: 'logo/horizontal/propvest-logo-dark.svg',
  footerLogo: 'logo/icons/propvest_icon.svg',
  backgroundImage: 'bg/propvest.png',
  logoContainsName: true,
  supportEmail: 'service@propvest.de',
  supportPhone: '+49 40 797 29 57 - 0',
  colors: {
    appBackground: '#fafafa',
    // https://www.figma.com/file/AlbsYsoPS5GB0i0vz5pynp/Propvest-Styleguide?node-id=0%3A1
    info: '#2196F3',
    warning: '#FFC107',

    primary: '#4AA391',
    'primary-1': '#e7f3f1',
    'primary-2': '#A1D4CA',
    'primary-3': '#4AA391',
    'primary-4': '#409281',

    accent: '#E99100',
    secondary: '#E99100',
    'secondary-1': '#fdf4e6',
    'secondary-2': '#fbe9cc',
    'secondary-3': '#f9deb3',
    'secondary-4': '#DE8B03',
    'secondary-5': '#D48400',
    'secondary-6': '#CE8102',

    'grey-900': '#212121',
    'grey-800': '#2e2d2c',
    'grey-700': '#616161',
    'grey-600': '#757575',
    'grey-500': '#9e9e9e',
    'grey-400': '#bdbdbd',
    'grey-300': '#e3e3e3',
    'grey-200': '#eeeeee',
    'grey-100': '#f5f5f5',
    'grey-50': '#fafafa',

    'text-light': '#FFFFFF',
    'text-dark': '#2E2D2C',

    success: '#10b610',
    error: '#E03D3D',

    // product colors
    'product-1': '#F9E9BF',
    'product-2': '#C4D7D7',
    'product-3': '#D5CDC1',
  },
};

export const getBrandSchema = () => {

  let schema = exporo;

  if (window.location.href.indexOf('propvest') >= 0) {
    schema = propvest;
  }

  return schema;
};