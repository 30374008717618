
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'exporo-menu',
})
class ExporoMenu extends Vue {
  @Prop()
  private id?: string;

  private isOpen: boolean = false;
  private showTimout: number = 0;

  mounted() {
    document.getElementById('app').addEventListener('scroll', this.closeOptions);
    document.body.addEventListener('click', this.closeOptions);
  }

  destroyed() {
    document.getElementById('app').removeEventListener('scroll', this.closeOptions);
    document.body.removeEventListener('click', this.closeOptions);
  }

  toggleOptions() {
    if (this.isOpen) {
      this.closeOptions();
    } else {
      this.openOptions();
    }
  }

  closeOptions() {
    this.showTimout = setTimeout(() => this.isOpen = false, 100);
  }

  openOptions() {
    this.isOpen = true;

    this.setOptionsPosition();
  }

  beforeDestroy() {
    clearTimeout(this.showTimout);
  }

  setOptionsPosition() {
    this.showTimout = setTimeout(() => {
      const trigger = this.$refs.trigger as HTMLElement;
      const options = this.$refs.options as HTMLElement;

      if(!trigger || !options){
        return null;
      }

      const position = trigger.getBoundingClientRect();

      options.style.top = `${Math.ceil(position.top + position.height)}px`;
      options.style.left = `${Math.ceil(position.left)}px`;
      options.style.width = `${Math.ceil(position.width)}px`;

      return null;
    }, 10);
  }
}

export default ExporoMenu;
