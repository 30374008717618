
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'exporo-tooltip',
})
class ExporoMenu extends Vue {

  private isOpen: boolean = false;
  private isMobile: Boolean = window.innerWidth < 768;
  private showTimout: number = 0;

  hide() {
    this.isOpen = false;
  }

  show() {
    this.isOpen = true;
    this.setOptionsPosition();
  }

  handleClick(e) {
    if (this.isMobile && !this.isOpen) {
      e.preventDefault();
      e.stopPropagation();
      this.show();
    }
  }

  beforeDestroy() {
    clearTimeout(this.showTimout);
  }

  setOptionsPosition() {
    this.showTimout = setTimeout(() => {
      const trigger = this.$refs.trigger as HTMLElement;
      const content = this.$refs.content as HTMLElement;

      if(!trigger || !content){
        return null;
      }

      const triggerPos = trigger.getBoundingClientRect();
      const contentPos = content.getBoundingClientRect();

      content.style.top = `${Math.ceil(triggerPos.top + triggerPos.height) + 5}px`;
      content.style.left = `${Math.ceil(triggerPos.left + (triggerPos.width / 2) - contentPos.width / 2)}px`;

      return null;
    }, 10);
  }
}

export default ExporoMenu;
