import store from '@/store';
import LocalStorage from './LocalStorage';
import { getOnboardingDomainURL } from '@/utils/Auth';

/**
 * 
 * Redirects user to user service onboarding application
 */
export const redirectToOnboarding = () => {
  window.location.href = `${getOnboardingDomainURL(process.env.VUE_APP_EXPORO_USER_URL)}`;
};

/**
 * Show sign in modal
 * Redirects user to exporo signin application
 */
export const redirectToLogin = () => {

  if (!LocalStorage.has('REDIRECT_TO')) {
    // means it could be an axios redirect
    LocalStorage.add('REDIRECT_TO', `${window.location.pathname}${window.location.hash}${window.location.search}`);
  }

  window.location.href = `${getOnboardingDomainURL(process.env.VUE_APP_EXPORO_USER_URL)}/#/signin?redirectURL=${window.location.origin}${window.location.pathname}`;

  // show sign in modal
  store.dispatch('appState/signedOut');
};
