import axios from 'axios';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { ExporoUser, BaaderUser, ProfileState, IdToken } from '@/store/profile/types';
import { RootState } from '@/store/types';
import { getIdToken } from '@/utils/Auth';
import parseJWT from '@/utils/parseJWT';
import { identifySentryUser } from '@/plugins/sentry';
import { getOnboardingDomainURL } from '@/utils/Auth';

const state: ProfileState = {
  exporoUser: null,
  baaderUser: null,
  idToken: null,
};

const mutations: MutationTree<ProfileState> = {
  SET_EXPORO_USER(state: ProfileState, exporoUser: ExporoUser) {
    state.exporoUser = exporoUser;
  },
  SET_BAADER_USER(state: ProfileState, baaderUser: BaaderUser) {
    state.baaderUser = baaderUser;
  },
  SET_ID_TOKEN(state: ProfileState, idToken: IdToken) {
    state.idToken = idToken;
  },
};

const getters: GetterTree<ProfileState, RootState> = {
  exporoUser: (state: ProfileState) => state.exporoUser,
  baaderUser: (state: ProfileState) => state.baaderUser,

  exporoId: (state: ProfileState): string => {
    const { idToken } = state;
    const id = (idToken && idToken.user_id) || '';

    return `${id}`;
  },

  fullName(state: ProfileState): string {
    const { exporoUser } = state;
    if (!exporoUser) {
      return '';
    }

    const { naturalUser } = exporoUser;
    const firstName = naturalUser.firstName;
    const lastName = naturalUser.lastName;

    return `${firstName} ${lastName}`;
  },
};

const actions: ActionTree<ProfileState, RootState> = {
  fetchIdToken({ commit }: { commit: Function }) {
    const idToken = getIdToken();
    if (idToken) {
      const parsedToken = parseJWT(idToken);
      commit('SET_ID_TOKEN', parsedToken);
    }
  },

  validateUser({ commit, dispatch }: { commit: Function, dispatch: Function }, exporoId: number) {
    return axios.get(`${getOnboardingDomainURL(process.env.VUE_APP_EXPORO_USER_API)}/user/metaData`)
      .then(({ data }) => {
        commit('SET_EXPORO_USER', data);

        return dispatch('fetchUser', exporoId);
      })
      .catch(error => {
        commit('SET_EXPORO_USER', null);

        return Promise.reject(error.response);
      });
  },


  fetchUser({ commit, dispatch }: { commit: Function, dispatch: Function }, exporoId: number) {

    const userQuery = exporoId ? '?user=' + exporoId : '';

    return axios.get(`${process.env.VUE_APP_BAADER_DEPOT_API_URL}/current-user` + userQuery)
      .then(async (response) => {
        const baaderUser = response.data;
        identifySentryUser(baaderUser);

        commit('SET_BAADER_USER', baaderUser);

        await dispatch('depot/fetchDocuments', {}, { root: true });
        await dispatch('pat/fetchDocuments', {}, { root: true });
        await dispatch('depot/fetchUserDepotData', {}, { root: true });

        return Promise.resolve(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },

  signOut() {
    const request = (path: string, params: any, method: string = 'post') => {
      const form = document.createElement('form');
      form.method = method;
      form.action = path;
      for (const key in params) {
        const hiddenField = document.createElement('input');
        hiddenField.type = 'hidden';
        hiddenField.name = key;
        hiddenField.value = params[key];
        form.appendChild(hiddenField);
      }
      document.body.appendChild(form);
      form.submit();
    };
    const payload = {
      redirectURL: `${window.location.origin}`,
    };

    return request(`${getOnboardingDomainURL(process.env.VUE_APP_EXPORO_USER_API)}/user/session/flush`, payload);
  },
};

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
