class SessionStorage {
    public static add(key: string, value: any) {
        sessionStorage.setItem(key, String(value));
    }

    public static get(key: string) {
        if (SessionStorage.has(key)) {
            return sessionStorage.getItem(key);
        }

        return null;
    }

    public static remove(key: string) {
        if (SessionStorage.has(key)) {
            sessionStorage.removeItem(key);
        }
    }

    public static has(key: string): boolean {
        return !!sessionStorage.getItem(key);
    }
}

export default SessionStorage;
