
import { Component, Vue, Prop } from 'vue-property-decorator';
import ExporoTooltip from '@/components/_common/Tooltip.vue';

@Component({
  name: 'app-header',
  components: {
    ExporoTooltip,
  },
})
export default class AppHeader extends Vue {
  @Prop({ default: 0, required: true }) balance: string;
  @Prop({ default: 0, required: true }) payouts: string;
  @Prop({ default: 'exporo', required: true }) brandName: string;

}
