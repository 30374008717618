var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{key:_vm.$i18n.language,staticClass:"app",class:_vm.brandName,attrs:{"id":"app"}},[(_vm.createDepotAccount)?_c('Modal',{attrs:{"active":true,"brandName":_vm.brandName,"name":"warning-modal","message":"test"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('a',{staticClass:"btn light",attrs:{"href":_vm.dashboardURL}},[_c('i',{staticClass:"material-icons"},[_vm._v("open_in_browser")]),_vm._v(" "+_vm._s(_vm.$t("appNavigation.exporoPages.Dashboard"))+" ")])]},proxy:true}],null,false,1611613219)},[_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t('appMessages.info', {
          context: '417',
          supportlink: `<a target='_blank' href='${_vm.$t(
            'appMessages.supportPath',
            { context: _vm.brandName }
          )}'>
            ${_vm.$t('appMessages.supportTitle', { context: _vm.brandName })}
            </a> `,
        })
      )}})]):_vm._e(),(!_vm.isPropvest)?_c('ExporoLoading',{attrs:{"isLoading":_vm.isLoadingUser}}):_vm._e(),_c('AppLoadingBar',{attrs:{"is-loading":_vm.isLoading}}),(_vm.isExporoWeb)?_c('AppSidebar',{attrs:{"menuOpen":_vm.menuOpen,"toggleMenu":_vm.toggleMenu,"fullName":_vm.fullName,"exporoId":_vm.exporoId,"signOut":_vm.signOut}}):_vm._e(),(_vm.isPropvest && _vm.allScriptsLoaded)?_c('custom-header',{key:`propvest-header-${_vm.user ? _vm.user.id : ''}`,attrs:{"name":"custom-header","user":_vm.user,"routes":_vm.propvestHeaderRoutes,"type":_vm.user ? 'secured': 'focused',"show-support-button":true,"show-baader-link":true,"logo-url":_vm.logoURL}}):_vm._e(),(_vm.allScriptsLoaded)?_c('main',{staticClass:"app__wrapper"},[_c('AppHeader',{attrs:{"brandName":_vm.brandName,"menuOpen":_vm.menuOpen,"balance":_vm.balance,"payouts":_vm.payoutBalance}}),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('div',{staticClass:"wrapper"},[_c('router-view')],1)])],1):_vm._e(),(_vm.isPropvest)?_c('footer',{staticClass:"app__footer"},[(_vm.allScriptsLoaded)?_c('custom-footer',{attrs:{"logo-url":_vm.logoURL,"current-locale":"de","brand-name":"propvest"}}):_vm._e()],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }