import Vue from 'vue';
import Vuelidate from 'vuelidate';
import { Component } from 'vue-property-decorator';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { configAxios } from '@/plugins/axios';
import { configI18n } from '@/plugins/i18n';
import { configSentry } from '@/plugins/sentry';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/theme-chalk/display.css';
import VueCurrencyInput from 'vue-currency-input';


// configure pluggins
configI18n();
configAxios();
configSentry();

// config vuelidate
Vue.use(Vuelidate);
Component.registerHooks(['validations']);

Vue.use(ElementUI, { locale });
Vue.use(VueCurrencyInput, { globalOptions: { currency: 'EUR' }});
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');


