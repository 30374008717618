import axios from 'axios';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { PatDocument, PatState } from './types';
import { RootState } from '@/store/types';

const state: PatState = {
    documents: undefined,
    loadingData: false,
};

const mutations: MutationTree<PatState> = {
    SET_LOADING_DATA(state: PatState, loadingData: boolean) {
        state.loadingData = loadingData;
    },
    SET_DOCUMENTS(state: PatState, documents: Array<PatDocument>) {
        state.documents = documents;
    },
};

const getters: GetterTree<PatState, RootState> = {
    loadingData: (state: PatState) => state.loadingData,
    documents: (state: PatState) => state.documents,
};

const actions: ActionTree<PatState, RootState> = {

    fetchDocuments({commit, rootState}: { commit: Function, rootState: any, dispatch: Function }) {
        if (!rootState.profile.baaderUser) {
            return null;
        }
        commit('SET_LOADING_DATA', true);
        commit('SET_DOCUMENTS', undefined);

        return axios.get(`${process.env.VUE_APP_PAT_API}/tax-reports?security_type=yield_baader`)
            .then(response => {
                commit('SET_DOCUMENTS', response.data);

                return Promise.resolve(response.data);
            })
            .catch(error => {
                commit('SET_DOCUMENTS', undefined);

                return Promise.reject(error.response);
            })
            .finally(() => commit('SET_LOADING_DATA', false));
    },
    downloadDocument({ commit }, fileName ) {
        return axios.get(
            `${process.env.VUE_APP_PAT_API}/tax-reports/${fileName}`,
            {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                },
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                return Promise.reject(error.response);
            });
    },
};

export const pat: Module<PatState, RootState> = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
