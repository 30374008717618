import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import { AppState } from './types';
import { RootState } from '../types';
import { Brand } from '@/utils/brand-schemas';

const state: AppState = {
  isLoading: false,
  blockedUI: false,
  signedOut: false,
  menuOpen: false,
  createDepotAccount: false,
  brandSchema: null,
};

const mutations: MutationTree<AppState> = {
  SET_LOADING(state: AppState, loadingState:Boolean) {
    state.isLoading = loadingState;
  },
  
  SET_BLOCK_UI(state: AppState) {
    state.blockedUI = true;
  },

  SET_SIGNED_OUT(state: AppState, signedOut:boolean) {
    state.signedOut = signedOut;
  },

  TOGGLE_MENU(state: AppState) {
    state.menuOpen = !state.menuOpen;
  },

  SET_CREATE_ACCOUNT(state: AppState, createDepotAccount:boolean) {
    state.createDepotAccount = createDepotAccount;
  },

  SET_BRAND_SCHEMA(state: AppState, brandSchema: Brand) {
    state.brandSchema = brandSchema;
  },
};

const getters: GetterTree<AppState, RootState> = {
  isLoading: (state: AppState) => state.isLoading,
  isBlockedUI: (state: AppState) => state.blockedUI,
  signedOut: (state: AppState) => state.signedOut,
  menuOpen: (state: AppState) => state.menuOpen,
  createDepotAccount: (state: AppState) => state.createDepotAccount,
  brandSchema: (state: AppState) => state.brandSchema,
};

const actions: ActionTree<AppState, RootState> = {
  startLoader({ commit }: { commit: Function}) {
    commit('SET_LOADING', true);
  },

  stopLoader({ commit }: { commit: Function }) {
    commit('SET_LOADING', false);
  },

  blockUI({ commit }: { commit: Function, }) {
    commit('SET_BLOCK_UI', true);
  },

  signedOut({ commit }: { commit: Function, }) {
    commit('SET_SIGNED_OUT', true);
  },

  unblockUI({ commit }: { commit: Function, }) {
    commit('SET_BLOCK_UI', false);
  },

  toggleMenu({ commit }: { commit: Function, }) {
    commit('TOGGLE_MENU');
  },

  createDepotAccount({ commit }: { commit: Function, }) {
    commit('SET_CREATE_ACCOUNT', true);
  },

  setBrandSchema({ commit }: { commit: Function, }, payload: Brand) {
    commit('SET_BRAND_SCHEMA', payload);
  },
};

export const appState: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
