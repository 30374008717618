/**
 * Exporo pages
 * 
 * Don't forget to link the title to the corresponding translation key under appNavigation.ExporoPages
 *
*/
export const exporoPagesEnum = {
  COCKPIT: { title: 'Cockpit', url: process.env.VUE_APP_COCKPIT_URL, icon:'account_balance_wallet' },
  EMONEY: { title: 'Emoney', url: process.env.VUE_APP_EXPORO_WALLET_URL, icon:'account_balance_wallet' },
  BAADER: { title: 'Baader', url: process.env.VUE_APP_BASE_URL, icon:'account_balance_wallet' },
  TRADING: { title: 'Trading', url: process.env.VUE_APP_EXPORO_TRADE_URL, icon:'repeat' },
};

export default exporoPagesEnum;
