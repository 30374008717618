
import { Component, Vue } from 'vue-property-decorator';
import AppBase from '@/views/AppBase.vue';

@Component({
  components: {
    AppBase,
  },
})
export default class App extends Vue { }
